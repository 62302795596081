<template>
  <div>
    <header class="route-header">
      <div class="route-header--left">
        <img src="@/assets/img/hsi_blue-logo-only.png" alt="" />
      </div>
      <div class="route-header--right">
        <h1 class="text-3xl">ECR</h1>
      </div>

      <div class="validation-cards--item cert no-hover">
        <span class="card-header">Certification Status</span>
        <div class="card-icon" margin-top="0px">
          <img
            src="@/assets/img/circular-label-with-certified-stamp.png"
            alt=""
          />
          <span class="card-total">100% Validation</span>
        </div>
      </div>
    </header> 
    <div class="route-header items-center secondary">
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Location</span>
          <span class="box-label">England</span>
        </div>
      </div>      
    </div>

  <nav class="route-nav">
    <ul>
      <li @click="editStageSummary('ecrsummary')" :class="stageSummary == 'ecrsummary' ? 'active' : ''"><span>ECR Summary</span></li>
      <li @click="editStageSummary('inpatientecr')" :class="stageSummary == 'inpatientecr' ? 'active' : ''"><span>Inpatient ECR</span></li>
      <li @click="editStageSummary('sai')" :class="stageSummary == 'sai' ? 'active' : ''"><span>Strategic Actionable Insights</span></li>
      <li @click="editStageSummary('ecrinpatientmanagement')" :class="stageSummary == 'ecrinpatientmanagement' ? 'active' : ''"> <span>ECR Inpatient Management</span></li>
      <li @click="editStageSummary('ecropdmanagement')" :class="stageSummary == 'ecropdmanagement' ? 'active' : ''"> <span>ECR OPD Management</span></li>
      <li @click="editStageSummary('ecropd')" :class="stageSummary == 'ecropd' ? 'active' : ''"> <span>ECR OPD</span></li>      
      <li @click="editStageSummary('ecrstandardsummary')" :class="stageSummary == 'ecrstandardsummary' ? 'active' : ''"> <span>ECR Standard Summary</span></li>
      <li @click="editStageSummary('ecrsummarywlbacklog')" :class="stageSummary == 'ecrsummarywlbacklog' ? 'active' : ''"> <span>ECR Summary WL Backlog</span></li>
      
      
      <!--li @click="editStageSummary('testTable')" :class="stageSummary == 'testTable' ? 'active' : ''"> <span>Test</span></li--> 
    </ul>
  </nav>

  <div v-if="stageSummary == 'ecrsummary'|| stageSummary == 'inpatientecr' || stageSummary == 'sai' || stageSummary == 'ecrinpatientmanagement' || stageSummary == 'ecropdmanagement' || stageSummary == 'ecropd' || stageSummary == 'ecrstandardsummary' || stageSummary == 'ecrsummarywlbacklog'">
    <div class="route-content">
    <div v-for="(item,id) in dataSets">
     <div class="search-table-outter wrapper">
      <table class="search-table inner">
        <thead>
          <!--th scope="col"  v-for="(v) in getHeaderData(item, id)" :key="v">{{v}}</th -->                                  
          <th scope="col"  v-for="(v) in getHeaderData(item, id)">{{v}}</th>  
        </thead>
        <tbody>
          <tr v-for="(row, idx1) in dataTable[id]" >
            <td v-for="(col, idx2) in row" >            
              <div v-if="dataTable[id][idx1][idx2] == ''"><div v-html="message"/></div>
              <div v-else-if="dataTable[id][idx1][idx2] == '-'"><div v-html="message"/></div>
              <div v-else> {{  dataTable[id][idx1][idx2] }}</div>     

              <!--tr v-for="(row, idx1) in dataTable[id]" :key="row">
            <td v-for="(col, idx2) in row" :key="col">            
              <div v-if="dataTable[id][idx1][idx2] == ''"><div v-html="message"/></div>
              <div v-else-if="dataTable[id][idx1][idx2] == '-'"><div v-html="message"/></div>
              <div v-else> {{  dataTable[id][idx1][idx2] }}</div --> 
            </td>                         
          </tr>                              
        </tbody>
      </table>
     </div>
    </div>
  </div>
</div>
</div>
</template>
<script>

export default {
 
  data() {
    return {    
      stageSummary: "ecrsummary",
      apiPayload: { speciality: "", apiString: "ecr_england" },      
      dataSetType: { columnHeaderLine: false, index: 0 },
      specialityType: "Patient Episodes",
      firstUpdate: false,
      message: "<BR />",
      metricUpdate: true,
      metricYear: "2023",
      numValues: 0,
      dataSetIndex: 0,
      numSets: 0,
      newObjectKeys: {},
      numItems: 0,
      header: {},
      dataSets: {},
      indexYear: -1,
      objectTest: {},
      dataTable: {},
      dataRow: {},    
      // determines whether the first column displays the first value in headers or not
      columnHeaderLine: true,
    };
  },
  updated() {
    if (this.firstUpdate == false) {
      //console.log ("updated: " + this.stageSummary);
      //this.editStageSummary (this.stageSummary);
      //console.log("updated");
          
      this.firstUpdate = true;
    }
  },
  beforeMount() {
    console.log("start beforeMount");
    this.apiPayload.apiString = "ecr_england";
    this.getMongoDBData();
    console.log ("end beforeMount");
  },
  mounted() {
    console.log("mounted");
    
    //console.log ("this.numValues: ", this.numValues);
    //console.log ("this.numSets: ", this.numSets);
    //console.log ("this.numItems: ", this.$store.state.prototypedata.specialTypeData[this.getDataSetIndex("control")].control.length);

    //this.numItems = this.$store.state.prototypedata.specialTypeData[this.getDataSetIndex("control")].control.length;        
  },
  computed: {
    store() {
      return this.$store.state.prototypedata;
    },
    specialityName() {
      return this.$store.state.prototypedata.specialityName;
    },    
  },
  methods: { 

    getFirstColumnData (fieldname)
    {
      var index = -1;
      var colData = new Array();
      //colData.push ("");
      console.log ("getFirstColumnData ( " + fieldname +")");

      if (this.$store.state.prototypedata.specialTypeData.length > 0) 
      {
        index = this.getDataSetNamedandValueIndex ("field", fieldname, false );

        if (index > -1)
        {
          //console.log (" this.$store.state.prototypedata.specialTypeData[" + String(index) + "]: ",  this.$store.state.prototypedata.specialTypeData[index]);

          for (let i = 0; i< this.$store.state.prototypedata.specialTypeData[index].control.length; i++)
          {
            colData.push (this.$store.state.prototypedata.specialTypeData[index].control[i]);
          }
          return colData;
        }

      }
    },

  
    
    getHeaderData (fieldname, id)
    {
      var index = -1;
      var headerData = new Array();

      console.log ("getHeaderData (" + fieldname + "," + id + ");");

      if (this.columnHeaderLine)
      {
            headerData.push ("");
      }

      
      if (this.$store.state.prototypedata.specialTypeData.length > 0) 
      {
        index = this.getDataSetNamedandValueIndex ("field", fieldname, false);

        if (index > -1)
        {
          //console.log (" this.$store.state.prototypedata.specialTypeData[" + String(index) + "]: ",  this.$store.state.prototypedata.specialTypeData[index]);

          for (let i = 0; i< this.$store.state.prototypedata.specialTypeData[index].header.length; i++)
          {
            headerData.push (this.$store.state.prototypedata.specialTypeData[index].header[i]);
          }

          //console.log ("headerData: ", headerData);


          return headerData;
        }

      }
    },
    getDataSetIndex(item) {
      var i = 0;
      var id = -1;

      if (this.$store.state.prototypedata.specialTypeData.length > 0) {
        for (
          i = 0;
          i < this.$store.state.prototypedata.specialTypeData.length;
          i++
        ) {
          if (item in this.$store.state.prototypedata.specialTypeData[i]) {
            id = i;
          }
        }
      }

      return id;
    },

    getDataSetNamedandValueIndex(item, value, debug) {
      var i = 0;
      var id = -1;
  
      if (debug)
      {
        console.log ("getDataSetNamedandValueIndex");
        console.log ("this.$store.state.prototypedata.specialTypeData.length ", this.$store.state.prototypedata.specialTypeData.length );
      }

      if (this.$store.state.prototypedata.specialTypeData.length > 0) 
      {
        for (i = 0; i < this.$store.state.prototypedata.specialTypeData.length; i++) 
        {
          if (item in this.$store.state.prototypedata.specialTypeData[i]) 
          {
            for (let trait in this.$store.state.prototypedata.specialTypeData[i])
            {
              if (trait == item)
              {
                if (this.$store.state.prototypedata.specialTypeData[i][trait] == value)
                {
                    id = i;
                    
                    if (debug)
                    {
                      console.log ("trait: ", trait);                
                      console.log ("this.$store.state.prototypedata.specialTypeData[i][trait] = ", this.$store.state.prototypedata.specialTypeData[i][trait]);
                      console.log ("id = ", id);
                    }
                    
                    break;
                }
              }

            }
            //console.log ("value: ", value);
            //console.log (item);
            //id = i;
          }
        }
      }
      //console.log("getDataSetIndex", item, id);

      return id;
    },
   
    getMongoDBData() {
        console.log ("start getMongoDBData");
        this.$store.dispatch("GetSpecialTypeData", this.apiPayload).then((data) => {
        console.log("data", data);
      });
       console.log ("end getMongoDBData");
    },    
    isEmpty(obj) {
      for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          return false;
        }
      }

      return JSON.stringify(obj) === JSON.stringify({});
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },  
    isUndefined(obj)  
    {
      let undefinedVal = true;

      if (typeof obj != "undefined")
      {
        undefinedVal = false;
      }

      return undefinedVal;
    },
    isNumeric(str) 
    {
        if (typeof str != "string") 
        {
            return false // we only process strings!  
        }
        
        // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        return !isNaN(str) && !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    },

    createNewerDataTable (id,i, j)
    {
      console.log ("createNewDataTable: (" + String(id) + "," + String (i) + ", " + String (j) + ");");
      //delete this.dataTable;
      //this.dataTable = {};

      if (this.isEmpty(this.dataTable) == false || typeof this.dataTable !== "undefined") 
      {
         console.log ("1");
      } 
      else
      {
        console.log ("2");
      }

      //console.log ("this.dataTable.length: ", this.dataTable.length);
      //console.log ("this.dataTable[0].length: ", this.dataTable[0].length);
      //console.log ("this.dataTable[1].length: ", this.dataTable[1].length);

      this.dataTable[id] = []
      this.dataTable[id].id = id
   

      for (let i_i = 0; i_i<=i; i_i++)
      {
          //console.log ("3");
          this.dataTable[id][i_i] = []
          //console.log ("4");
          for (let j_j= 0; j_j<=j; j_j++)
          {
            //this.dataTable[id][i_i][j_j] = String(id) + "." + String(i_i) + "." + String(j_j);
            this.dataTable[id][i_i][j_j] = "";
            //console.log ("setting id: " + String(id) + " row: " + String(i_i) + " col: " + String(j_j) + " = " + this.dataTable[id][i_i][j_j]);
           
          }
       }    
    },

    createDataTable (id,year, newdocumentset,columns)
    {

      console.log ("createDataTable (" + id + "," + year + "," + newdocumentset + "," + columns + ")");
      var documentset = newdocumentset + "_year" + year;
      this.indexYear = -1;

      console.log ("documentset = ", documentset);

      //console.log ("columns", columns);
      var columnCount = columns.length;

      //console.log ("columnCount: ", columnCount);

      //console.log ("newdocumentset: ", newdocumentset);

      var controlColumnItems =  this.getFirstColumnData (newdocumentset);

      //console.log ("controlItems: ", controlColumnItems);

      //console.log ("generateTableData: ", year);
        //console.log ("documentSet: ", documentset);

        this.indexYear = this.getDataSetNamedandValueIndex ("name", documentset,  false);

       

        this.createNewerDataTable (id,controlColumnItems.length, columnCount -1);

        console.log ("this.dataTable: ", this.dataTable[0].length);
        console.log ("this.dataTable: ", this.dataTable[0][0].length);
        console.log ("this.dataTable: ", this.dataTable);

        console.log ("controlColumnItems: ", controlColumnItems.length);

        for (let i = 0; i<controlColumnItems.length; i++)
        {
            var documentName = newdocumentset + "_" + String (i+1);
            console.log ("documentName: ", documentName);
            console.log ("columnCount: ", columnCount);

            for (let j = 0; j<columnCount; j++)
            {
              if (j == 0)
              {
                  this.dataTable[id][i][j] = controlColumnItems[i];
              }
              else
              {
                //console.log ("[" + String(i) + "][" + String(j) + "] = specialTypeData[" + String(this.indexYear) + "]["+ documentName +"][" + String(j) + "] = ", this.$store.state.prototypedata.specialTypeData[this.indexYear][documentName][j]);
                this.dataTable[id][i][j] = this.$store.state.prototypedata.specialTypeData[this.indexYear][documentName][j-1];
              }
            }
        }

    },
    generateDataTable()
    {
      console.log ("generate");
      console.log ("this.dataSets.length = ", this.dataSets.length);

      for (let i = 0; i < this.dataSets.length; i++)
      {
        var columns = this.getHeaderData (this.dataSets[i]);
        console.log ("columns: ", columns);

        this.createDataTable (i, 1, this.dataSets[i], columns);
      }
    },
   
    editStageSummary(stageSummary) {
      console.log ("editStageSummary (" + stageSummary + ")");
      this.stageSummary = stageSummary;                    

      if (this.stageSummary == "ecrsummary")
      {
        this.dataSets = new Array ();
        this.dataSets.push ("ecr_summary");
        this.columnHeaderLine = true;      
        this.generateDataTable();
      }

      if (this.stageSummary == "inpatientecr")
      {
        this.dataSets = new Array ();
        this.dataSets.push("inpatient_ecr");
        this.columnHeaderLine = false;
        this.generateDataTable();
      }

      
      if (this.stageSummary == "sai")
      {
        this.dataSets = new Array ();
        this.dataSets.push ("sai");
        this.columnHeaderLine = false;
        this.generateDataTable();
      }

      if (this.stageSummary == "ecrinpatientmanagement")
      {
        this.dataSets = new Array ();
        this.dataSets.push ("ecr_inpatient1");
        this.dataSets.push ("ecr_inpatient2");
        this.columnHeaderLine = false;
        this.generateDataTable();
      }

      if (this.stageSummary == "ecropdmanagement")
      {
        this.dataSets = new Array ();
        this.dataSets.push ("ecr_opd_management1");
        this.dataSets.push ("ecr_opd_management2");
        this.columnHeaderLine = false;
        this.generateDataTable();
      }

      if (this.stageSummary == "ecropd")
      {
        this.dataSets = new Array ();
        this.dataSets.push("ecr_opd");
        this.columnHeaderLine = false;
        this.generateDataTable();
      }

      if (this.stageSummary == "ecrstandardsummary")
      {
        this.dataSets = new Array ();
        this.dataSets.push("ecr_standard_summary");
        this.columnHeaderLine = false;
        this.generateDataTable();
      }

      if (this.stageSummary == "ecrsummarywlbacklog")
      {
        this.dataSets = new Array ();
        this.dataSets.push("ecr_summary_el_backlog");
        this.columnHeaderLine = false;
        this.generateDataTable();
      }


   

    

    },
  },
};
</script>
<style scoped>
.chartcontainersummaryone {
  border: 1px solid;
  border-color: #e5e9f2;
  height: 400px;
  display: inline-block;
  width: 350px; /* was 26 rem */
  margin-right: 20px;
  padding-bottom: 5px;
}
.title_text1 {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.hello1 {
color: #000000;
font-family: arial, sans-serif;
font-size: 16px;
font-weight: bold;
margin-top: 20px;
margin-bottom: 30px;
}

.search-table-outter {border:0px; /*solid red; */}
.search-table{table-layout: fixed; margin:40px auto 0px auto; }
.search-table, td, th{border-collapse:collapse; border:1px solid #1d0363;}
th{padding: 1rem; font-size:16px; background: rgba(#E5E9F2, .5); text-align: left; font-weight: 500; line-height: 1;}
td{ color: #858d9b; padding: 0.75rem 1rem;  border-bottom: 1px solid #e5e9f2;  line-height: 1;font-size: 12px; }
thead{padding: 1rem; font-size:22px; background-color: rgba(229, 233, 242, .5); text-align: left; font-weight: 500; line-height: 1;}

.search-table-outter { overflow-x: scroll; }
th, td { min-width: 200px; }
</style>
